
import React from 'react'

const Loader = ({isFetching}) => {
    {return isFetching ?   (
      <div className="page-preloader">
        <div className="loader">
          <div />
          <div />
        </div>
      </div>
  ):null}
}

export default Loader
