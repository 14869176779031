import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const sizeService = createApi({
  reducerPath: "sizes",
  tagTypes: ["sizes"],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://fine1jewels.com/mainapi/api/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      createSize: builder.mutation({
        query: (data) => ({
          url: "/sizes",
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["sizes"],
      }),
      getAllSizes: builder.query({
        query: () => ({
          url: "/sizes",
          method: "GET",
        }),
        providesTags: ["sizes"],
      }),
      removeSize: builder.mutation({
        query: (id) => ({
          url: `/sizes/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["sizes"],
      }),
    };
  },
});

export const {
  useCreateSizeMutation,
  useGetAllSizesQuery,
  useRemoveSizeMutation,
} = sizeService;
export default sizeService;
