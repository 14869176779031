import React, { useEffect, useState } from "react";
import TrustSection from "../../components/TrustSection/TrustSection";
import ProductGallery from "../../components/ProductGallery/ProductGallery";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProductQuery } from "../../store/services/productService";
import currency  from "currency-formatter";
import { addCart } from "../../store/reducers/cartReducer";
import { useDispatch, useSelector } from "react-redux";
import HTMLReactParser from "html-react-parser";
import toast from "react-hot-toast";
import { useCatProductsQuery } from "../../store/services/homeProducts";
import ProductsSwiper from "../../components/ProductsSwiper/ProductsSwiper";
import Loader from "../../components/Loader/Loader";
import LoginModal from "../../components/LoginModal/LoginModal";

const ProductDetails = () => {
  const { _id } = useParams();
  const { data, isFetching } = useGetProductQuery(_id);
  const { data:relatedproducts, isFetching:relatedproductloading } = useCatProductsQuery({name:data?.category,page:1});
  const dispatch = useDispatch();
const [quantity, setquantity] = useState(1)
const navigate = useNavigate();
let desc = data?.description ? HTMLReactParser(data?.description):'';
const { userToken, user } = useSelector((state) => state.authReducer);
const [showlogin, setshowlogin] = useState(false)

useEffect(() => {
  window.scrollTo(0, 0)
}, [])

  const addToCart_apihit = () => {
    const { _id, name: title, images:image } = data || {}; // Extracting _id, title, and image
    const cart = localStorage.getItem("cart");
    const cartItems = cart ? JSON.parse(cart) : [];
  
    // Check if the product is already in the cart
    const checkItem = cartItems.find((item) => item._id === _id);
  
    if (!checkItem) {
      // Add _id, title, and image to the cart
      const newProduct = { _id, title, image:image[0],quantity };
      dispatch(addCart(newProduct));
      cartItems.push(newProduct);
      toast.success(`${title} Added to cart`);
      localStorage.setItem("cart", JSON.stringify(cartItems));
    } else {
      toast.error(`${title} is already in cart`);
    }
  }
  


  const buyitnow_apihit = () => {
    const { _id, name: title, images:image } = data || {}; // Extracting _id, title, and image
    const cart = localStorage.getItem("cart");
    const cartItems = cart ? JSON.parse(cart) : [];
  
    // Check if the product is already in the cart
    const checkItem = cartItems.find((item) => item._id === _id);
  
    if (!checkItem) {
      // Add _id, title, and image to the cart
      const newProduct = { _id, title, image:image[0],quantity };
      dispatch(addCart(newProduct));
      cartItems.push(newProduct);
      toast.success(`${title} Proceeding to Buy`);
      localStorage.setItem("cart", JSON.stringify(cartItems));
      if (userToken) {
    navigate("/Checkout");
  } else {
    setshowlogin(true)
  }
    } else {

      if (userToken) {
    navigate("/Checkout");
  } else {
    setshowlogin(true)
  }
    }
  }
  
  

  return (
    <>
    <LoginModal showModal={showlogin} setShowModal={setshowlogin}/>
      <div id="page" className="hfeed page-wrapper">
        <div id="site-main" className="site-main">
          <div id="main-content" className="main-content">
            <div id="primary" className="content-area">
              <div id="title" className="page-title">
                <div className="section-container">
                  <div className="content-title-heading">
                    <h1 className="text-title-heading">{data?.name}</h1>
                  </div>
                  <div className="breadcrumbs">
                    <a href="index.html">Home</a>
                    <span className="delimiter" />
                    <a href="shop-grid-left.html">Shop</a>
                    <span className="delimiter" />
                    {data?.name}
                  </div>
                </div>
              </div>
              <div id="content" className="site-content" role="main">
                <div
                  className="shop-details zoom"
                  data-product_layout_thumb="scroll"
                  data-zoom_scroll="true"
                  data-zoom_contain_lens="true"
                  data-zoomtype="inner"
                  data-lenssize={200}
                  data-lensshape="square"
                  data-lensborder=""
                  data-bordersize={2}
                  data-bordercolour="#f9b61e"
                  data-popup="false"
                >
                  <div className="product-top-info">
                    <div className="section-padding">
                      <div className="section-container p-l-r">
                        <div className="row">
                        {/* <div className="product-images col-lg-7 col-md-12 col-12">
                        <div className="row"> */}
                        
                          <ProductGallery imageData={data?.images}/>
                          
                        
                          {/* </div>
                          </div> */}
                          {/* <div className="product-images col-lg-7 col-md-12 col-12">
                            <div className="row">
                              <div className="col-md-2">
                                <div className="content-thumbnail-scroll">
                                  <div
                                    className="image-thumbnail slick-carousel slick-vertical"
                                    data-asnavfor=".image-additional"
                                    data-centermode="true"
                                    data-focusonselect="true"
                                    data-columns4={5}
                                    data-columns3={4}
                                    data-columns2={4}
                                    data-columns1={4}
                                    data-columns={4}
                                    data-nav="true"
                                    data-vertical='"true"'
                                    data-verticalswiping='"true"'
                                  >
                                    <div className="img-item slick-slide">
                                      <span className="img-thumbnail-scroll">
                                        <img
                                          width={600}
                                          height={600}
                                          src="media/product/1.jpg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                    <div className="img-item slick-slide">
                                      <span className="img-thumbnail-scroll">
                                        <img
                                          width={600}
                                          height={600}
                                          src="media/product/1-2.jpg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                    <div className="img-item slick-slide">
                                      <span className="img-thumbnail-scroll">
                                        <img
                                          width={600}
                                          height={600}
                                          src="media/product/2.jpg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                    <div className="img-item slick-slide">
                                      <span className="img-thumbnail-scroll">
                                        <img
                                          width={600}
                                          height={600}
                                          src="media/product/2-2.jpg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                    <div className="img-item slick-slide">
                                      <span className="img-thumbnail-scroll">
                                        <img
                                          width={600}
                                          height={600}
                                          src="media/product/3.jpg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-10">
                                <div className="scroll-image main-image">
                                  <div
                                    className="image-additional slick-carousel"
                                    data-asnavfor=".image-thumbnail"
                                    data-fade="true"
                                    data-columns4={1}
                                    data-columns3={1}
                                    data-columns2={1}
                                    data-columns1={1}
                                    data-columns={1}
                                    data-nav="true"
                                  >
                                    <div className="img-item slick-slide">
                                      <img
                                        width={900}
                                        height={900}
                                        src="media/product/1.jpg"
                                        alt=""
                                        title=""
                                      />
                                    </div>
                                    <div className="img-item slick-slide">
                                      <img
                                        width={900}
                                        height={900}
                                        src="media/product/1-2.jpg"
                                        alt=""
                                        title=""
                                      />
                                    </div>
                                    <div className="img-item slick-slide">
                                      <img
                                        width={900}
                                        height={900}
                                        src="media/product/2.jpg"
                                        alt=""
                                        title=""
                                      />
                                    </div>
                                    <div className="img-item slick-slide">
                                      <img
                                        width={900}
                                        height={900}
                                        src="media/product/2-2.jpg"
                                        alt=""
                                        title=""
                                      />
                                    </div>
                                    <div className="img-item slick-slide">
                                      <img
                                        width={900}
                                        height={900}
                                        src="media/product/3.jpg"
                                        alt=""
                                        title=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="product-info col-lg-5 col-md-12 col-12 ">
                            <h1 className="title">{data?.name}</h1>
                            <span className="price">
                            {data?.discount ? (  <del aria-hidden="true">
                                <span>{currency.format(data?.pricing.finalPrice +  data.discount, { code: "INR" })}</span>
                              </del>):null}
                              <ins>
                                <span> {currency.format(data?.pricing.finalPrice, { code: "INR" })}</span>
                              </ins>
                            </span>
                            <div className="rating">
                              <div className="star star-5" />
                              <div className="review-count">
                                (3<span> reviews</span>)
                              </div>
                            </div>
                            <div className="productcontainer">
      <section id="pdpage-pddetails" className="product-details">
        <section className="details-content">
        

          <div className="product-description">
            <div id="skucontentdiv" className="description-text">
              Set in {data?.metalDetails.karat} KT {data?.metalDetails.type}({data?.metalDetails.grossWeight} g) with diamonds ({data?.stoneDetails.diamond.carats} ct ) <br />
            </div>
          </div>

          <div className="details-boxes">
            <div className="details-box gold">
              <div className="box-header">
                <span className="icon gold-icon"></span>
                <p className="box-title">{data?.metalDetails.type}</p>
              </div>
               <div className="product-detail-meta">
                              <span className="sku-wrapper">
                                Gross: <span className="sku">{data?.metalDetails.grossWeight} gram</span>
                              </span>
                              <span className="posted-in">
                                Purity:{" "}
                                <a href="shop-grid-left.html" rel="tag">
                                  {data?.metalDetails.karat} kt
                                </a>
                              </span>
                             
                            </div>
            </div>

            <div className="details-box diamond">
              <div className="box-header">
                <span className="icon diamond-icon"></span>
                <p className="box-title">Diamond</p>
              </div>
              <div className="box-content">
              <div className="product-detail-meta">
                
                              <span className="sku-wrapper">
                              Setting: <span className="sku">Total No.: {data?.stoneDetails.diamond.pcs}</span>
                              </span>
                              <span className="posted-in">
                              Total Weight:{" "}
                                <a href="shop-grid-left.html" rel="tag">
                                {data?.stoneDetails.diamond.carats} ct
                                </a>
                              </span>
                             
                            </div>


             
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
                            {/* <div className="variations">
                              <table cellSpacing={0}>
                                <tbody>
                                  <tr>
                                    <td className="label">Size</td>
                                    <td className="attributes">
                                      <ul className="text">
                                        <li>
                                          <span>L</span>
                                        </li>
                                        <li>
                                          <span>M</span>
                                        </li>
                                        <li>
                                          <span>S</span>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="label">Color</td>
                                    <td className="attributes">
                                      <ul className="colors">
                                        <li>
                                          <span className="color-1" />
                                        </li>
                                        <li>
                                          <span className="color-2" />
                                        </li>
                                        <li>
                                          <span className="color-3" />
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div> */}
                            <div className="buttons">
                              <div className="add-to-cart-wrap">
                                <div className="quantity">
                                  <button type="button" onClick={() => setquantity(quantity+1)} className="plus">
                                    +
                                  </button>
                                  <input
                                    type="number"
                                    className="qty"
                                    step={1}
                                    min={0}
                                    max=""
                                    name="quantity"
                                    defaultValue={1}
                                    title="Qty"
                                    size={4}
                                    placeholder=""
                                    value={quantity}
                                    inputMode="numeric"
                                    autoComplete="off"
                                  />
                                  <button type="button" onClick={() => setquantity(quantity == 1 ? 1:quantity-1)} className="minus">
                                    -
                                  </button>
                                </div>
                                <div className="btn-add-to-cart">
                                <a href="#" onClick={(e) => {
    e.preventDefault(); // Prevents default link behavior
    console.log("ok ok ")
    addToCart_apihit(); // Calls your function
}} tabIndex={0}>
    Add to cart
</a>

                                </div>
                              </div>
                              <div
                                className="btn-quick-buy"
                                data-title="Wishlist"
                                onClick={() => buyitnow_apihit()}
                              >
                                <button className="product-btn">
                                  Buy It Now
                                </button>
                              </div>
                              <div
                                className="btn-wishlist"
                                data-title="Wishlist"
                              >
                                <button className="product-btn">
                                  Add to wishlist
                                </button>
                              </div>
                              <div className="btn-compare" data-title="Compare">
                                <button className="product-btn">Compare</button>
                              </div>
                            </div>
                            <div className="product-meta">
                              <span className="sku-wrapper">
                                SKU: <span className="sku">D2300-3-2-2</span>
                              </span>
                              <span className="posted-in">
                                Category:{" "}
                                <a href="shop-grid-left.html" rel="tag">
                                  Bracelets
                                </a>
                              </span>
                              <span className="tagged-as">
                                Tags:{" "}
                                <a href="shop-grid-left.html" rel="tag">
                                  Hot
                                </a>
                                ,{" "}
                                <a href="shop-grid-left.html" rel="tag">
                                  Trend
                                </a>
                              </span>
                            </div>
                            {/* <div className="social-share">
                              <a
                                href="#"
                                title="Facebook"
                                className="share-facebook"
                                target="_blank"
                              >
                                <i className="fa fa-facebook" />
                                Facebook
                              </a>
                              <a
                                href="#"
                                title="Twitter"
                                className="share-twitter"
                              >
                                <i className="fa fa-twitter" />
                                Twitter
                              </a>
                              <a
                                href="#"
                                title="Pinterest"
                                className="share-pinterest"
                              >
                                <i className="fa fa-pinterest" />
                                Pinterest
                              </a>
                            </div> */}
                            <TrustSection/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-tabs">
                    <div className="section-padding">
                      <div className="section-container p-l-r">
                        <div className="product-tabs-wrap">
                          <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                              <a
                                className="nav-link "
                                data-toggle="tab"
                                
                                role="tab"
                              >
                                Description
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                
                                role="tab"
                              >
                                Additional information
                              </a>
                            </li>
                            <li className="nav-item active">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                
                                role="tab"
                              >
                                Reviews (1)
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div
                              className="tab-pane fade "
                              id="description"
                              role="tabpanel"
                            >
                              {desc}
                            </div>
                            <div
                              className="tab-pane fade "
                              id="additional-information"
                              role="tabpanel"
                            >
                              <table className="product-attributes">
                                <tbody>
                                  <tr className="attribute-item">
                                    <th className="attribute-label">Color</th>
                                    <td className="attribute-value">
                                      Antique, Chestnut, Grullo
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div
                              className="tab-pane fade show active"
                              id="reviews"
                              role="tabpanel"
                            >
                              <div id="reviews" className="product-reviews">
                                <div id="comments">
                                  <h2 className="reviews-title">
                                    1 review for <span>Bora Armchair</span>
                                  </h2>
                                  <ol className="comment-list">
                                    <li className="review">
                                      <div className="content-comment-container">
                                        <div className="comment-container">
                                          <img
                                            src="media/user.jpg"
                                            className="avatar"
                                            height={60}
                                            width={60}
                                            alt=""
                                          />
                                          <div className="comment-text">
                                            <div className="rating small">
                                              <div className="star star-5" />
                                            </div>
                                            <div className="review-author">
                                              Peter Capidal
                                            </div>
                                            <div className="review-time">
                                              January 12, 2023
                                            </div>
                                          </div>
                                        </div>
                                        <div className="description">
                                          <p>
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Nam
                                            fringilla augue nec est tristique
                                            auctor. Donec non est at libero
                                            vulputate rutrum. Morbi ornare
                                            lectus quis justo gravida semper.
                                            Nulla tellus mi, vulputate
                                            adipiscing cursus eu, suscipit id
                                            nulla.
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  </ol>
                                </div>
                                <div id="review-form">
                                  <div id="respond" className="comment-respond">
                                    <span
                                      id="reply-title"
                                      className="comment-reply-title"
                                    >
                                      Add a review
                                    </span>
                                    <form
                                      action=""
                                      method="post"
                                      id="comment-form"
                                      className="comment-form"
                                    >
                                      <p className="comment-notes">
                                        <span id="email-notes">
                                          Your email address will not be
                                          published.
                                        </span>{" "}
                                        Required fields are marked{" "}
                                        <span className="required">*</span>
                                      </p>
                                      <div className="comment-form-rating">
                                        <label htmlFor="rating">
                                          Your rating
                                        </label>
                                        <p className="stars">
                                          <span>
                                            <a className="star-1" href="#">
                                              1
                                            </a>
                                            <a className="star-2" href="#">
                                              2
                                            </a>
                                            <a className="star-3" href="#">
                                              3
                                            </a>
                                            <a className="star-4" href="#">
                                              4
                                            </a>
                                            <a className="star-5" href="#">
                                              5
                                            </a>
                                          </span>
                                        </p>
                                      </div>
                                      <p className="comment-form-comment">
                                        <textarea
                                          id="comment"
                                          name="comment"
                                          placeholder="Your Reviews *"
                                          cols={45}
                                          rows={8}
                                          aria-required="true"
                                          required=""
                                          defaultValue={""}
                                        />
                                      </p>
                                      <div className="content-info-reviews">
                                        <p className="comment-form-author">
                                          <input
                                            id="author"
                                            name="author"
                                            placeholder="Name *"
                                            type="text"
                                            defaultValue=""
                                            size={30}
                                            aria-required="true"
                                            required=""
                                          />
                                        </p>
                                        <p className="comment-form-email">
                                          <input
                                            id="email"
                                            name="email"
                                            placeholder="Email *"
                                            type="email"
                                            defaultValue=""
                                            size={30}
                                            aria-required="true"
                                            required=""
                                          />
                                        </p>
                                        <p className="form-submit">
                                          <input
                                            name="submit"
                                            type="submit"
                                            id="submit"
                                            className="submit"
                                            defaultValue="Submit"
                                          />
                                        </p>
                                      </div>
                                    </form>
                                    {/* #respond */}
                                  </div>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 {relatedproducts?.products.filter(
            (item) => item._id !== data._id
          ).length > 0 ? <div className="product-related">
                    <div className="section-padding">
                      <div className="section-container p-l-r">
                        <div className="block block-products slider">
                          <div className="block-title">
                            <h2>Related Products</h2>
                          </div>
                          <div className="block-content">
                           
                           <ProductsSwiper data={relatedproducts?.products.filter(
            (item) => item._id !== data._id
          )} isFetching={relatedproductloading}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>:null}
                </div>
              </div>
              {/* #content */}
            </div>
            {/* #primary */}
          </div>
          {/* #main-content */}
        </div>
      </div>
      {/* Back Top button */}
      <div className="back-top button-show">
        <i className="arrow_carrot-up" />
      </div>
      {/* Search */}
      <div className="search-overlay">
        <div className="close-search" />
        <div className="wrapper-search">
          <form
            role="search"
            method="get"
            className="search-from ajax-search"
            action=""
          >
            <a href="#" className="search-close" />
            <div className="search-box">
              <button id="searchsubmit" className="btn" type="submit">
                <i className="icon-search" />
              </button>
              <input
                type="text"
                autoComplete="off"
                defaultValue=""
                name="s"
                className="input-search s"
                placeholder="Search..."
              />
              <div className="content-menu_search">
                <label>Suggested</label>
                <ul id="menu_search" className="menu">
                  <li>
                    <a href="#">Earrings</a>
                  </li>
                  <li>
                    <a href="#">Necklaces</a>
                  </li>
                  <li>
                    <a href="#">Bracelets</a>
                  </li>
                  <li>
                    <a href="#">Jewelry Box</a>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Wishlist */}
      <div className="wishlist-popup">
        <div className="wishlist-popup-inner">
          <div className="wishlist-popup-content">
            <div className="wishlist-popup-content-top">
              <span className="wishlist-name">Wishlist</span>
              <span className="wishlist-count-wrapper">
                <span className="wishlist-count">2</span>
              </span>
              <span className="wishlist-popup-close" />
            </div>
            <div className="wishlist-popup-content-mid">
              <table className="wishlist-items">
                <tbody>
                  <tr className="wishlist-item">
                    <td className="wishlist-item-remove">
                      <span />
                    </td>
                    <td className="wishlist-item-image">
                      <a href="shop-details.html">
                        <img
                          width={600}
                          height={600}
                          src="media/product/3.jpg"
                          alt=""
                        />
                      </a>
                    </td>
                    <td className="wishlist-item-info">
                      <div className="wishlist-item-name">
                        <a href="shop-details.html">Twin Hoops</a>
                      </div>
                      <div className="wishlist-item-price">
                        <span>$150.00</span>
                      </div>
                      <div className="wishlist-item-time">June 4, 2023</div>
                    </td>
                    <td className="wishlist-item-actions">
                      <div className="wishlist-item-stock">In stock</div>
                      <div className="wishlist-item-add">
                        <div data-title="Add to cart">
                          <a rel="nofollow" href="#">
                            Add to cart
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="wishlist-item">
                    <td className="wishlist-item-remove">
                      <span />
                    </td>
                    <td className="wishlist-item-image">
                      <a href="shop-details.html">
                        <img
                          width={600}
                          height={600}
                          src="media/product/4.jpg"
                          alt=""
                        />
                      </a>
                    </td>
                    <td className="wishlist-item-info">
                      <div className="wishlist-item-name">
                        <a href="shop-details.html">
                          Yilver And Turquoise Earrings
                        </a>
                      </div>
                      <div className="wishlist-item-price">
                        <del aria-hidden="true">
                          <span>$150.00</span>
                        </del>
                        <ins>
                          <span>$100.00</span>
                        </ins>
                      </div>
                      <div className="wishlist-item-time">June 4, 2023</div>
                    </td>
                    <td className="wishlist-item-actions">
                      <div className="wishlist-item-stock">In stock</div>
                      <div className="wishlist-item-add">
                        <div data-title="Add to cart">
                          <a rel="nofollow" href="#">
                            Add to cart
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="wishlist-popup-content-bot">
              <div className="wishlist-popup-content-bot-inner">
                <a className="wishlist-page" href="shop-wishlist.html">
                  Open wishlist page
                </a>
                <span className="wishlist-continue" data-url="">
                  Continue shopping
                </span>
              </div>
              <div className="wishlist-notice wishlist-notice-show">
                Added to the wishlist!
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Compare */}
      <div className="compare-popup">
        <div className="compare-popup-inner">
          <div className="compare-table">
            <div className="compare-table-inner">
              <a
                href="#"
                id="compare-table-close"
                className="compare-table-close"
              >
                <span className="compare-table-close-icon" />
              </a>
              <div className="compare-table-items">
                <table id="product-table" className="product-table">
                  <thead>
                    <tr>
                      <th>
                        <a href="#" className="compare-table-settings">
                          Settings
                        </a>
                      </th>
                      <th>
                        <a href="shop-details.html">Twin Hoops</a>{" "}
                        <span className="remove">remove</span>
                      </th>
                      <th>
                        <a href="shop-details.html">Medium Flat Hoops</a>{" "}
                        <span className="remove">remove</span>
                      </th>
                      <th>
                        <a href="shop-details.html">Bold Pearl Hoop Earrings</a>{" "}
                        <span className="remove">remove</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="tr-image">
                      <td className="td-label">Image</td>
                      <td>
                        <a href="shop-details.html">
                          <img
                            width={600}
                            height={600}
                            src="media/product/3.jpg"
                            alt=""
                          />
                        </a>
                      </td>
                      <td>
                        <a href="shop-details.html">
                          <img
                            width={600}
                            height={600}
                            src="media/product/1.jpg"
                            alt=""
                          />
                        </a>
                      </td>
                      <td>
                        <a href="shop-details.html">
                          <img
                            width={600}
                            height={600}
                            src="media/product/2.jpg"
                            alt=""
                          />
                        </a>
                      </td>
                    </tr>
                    <tr className="tr-sku">
                      <td className="td-label">SKU</td>
                      <td>VN00189</td>
                      <td />
                      <td>D1116</td>
                    </tr>
                    <tr className="tr-rating">
                      <td className="td-label">Rating</td>
                      <td>
                        <div className="star-rating">
                          <span style={{ width: "80%" }} />
                        </div>
                      </td>
                      <td>
                        <div className="star-rating">
                          <span style={{ width: "100%" }} />
                        </div>
                      </td>
                      <td />
                    </tr>
                    <tr className="tr-price">
                      <td className="td-label">Price</td>
                      <td>
                        <span className="amount">$150.00</span>
                      </td>
                      <td>
                        <del>
                          <span className="amount">$150.00</span>
                        </del>{" "}
                        <ins>
                          <span className="amount">$100.00</span>
                        </ins>
                      </td>
                      <td>
                        <span className="amount">$200.00</span>
                      </td>
                    </tr>
                    <tr className="tr-add-to-cart">
                      <td className="td-label">Add to cart</td>
                      <td>
                        <div data-title="Add to cart">
                          <a href="#" className="button">
                            Add to cart
                          </a>
                        </div>
                      </td>
                      <td>
                        <div data-title="Add to cart">
                          <a href="#" className="button">
                            Add to cart
                          </a>
                        </div>
                      </td>
                      <td>
                        <div data-title="Add to cart">
                          <a href="#" className="button">
                            Add to cart
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr className="tr-description">
                      <td className="td-label">Description</td>
                      <td>
                      {desc}
                      </td>
                    </tr>
                    <tr className="tr-content">
                      <td className="td-label">Content</td>
                      <td>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum. Sed ut perspiciatis unde omnis iste
                        natus error sit voluptatem accusantium doloremque
                        laudantium, totam rem aperiam, eaque ipsa quae ab illo
                        inventore veritatis et quasi architecto beatae vitae
                        dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                        voluptas sit aspernatur aut odit aut fugit, sed quia
                        consequuntur magni dolores eos qui ratione voluptatem
                        sequi nesciunt. Neque porro quisquam est, qui dolorem
                        ipsum quia dolor sit amet, consectetur, adipisci velit,
                        sed quia non numquam eius modi tempora incidunt ut
                        labore et dolore magnam aliquam quaerat voluptatem.
                      </td>
                      <td>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum. Sed ut perspiciatis unde omnis iste
                        natus error sit voluptatem accusantium doloremque
                        laudantium, totam rem aperiam, eaque ipsa quae ab illo
                        inventore veritatis et quasi architecto beatae vitae
                        dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                        voluptas sit aspernatur aut odit aut fugit, sed quia
                        consequuntur magni dolores eos qui ratione voluptatem
                        sequi nesciunt. Neque porro quisquam est, qui dolorem
                        ipsum quia dolor sit amet, consectetur, adipisci velit,
                        sed quia non numquam eius modi tempora incidunt ut
                        labore et dolore magnam aliquam quaerat voluptatem.
                      </td>
                      <td>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum. Sed ut perspiciatis unde omnis iste
                        natus error sit voluptatem accusantium doloremque
                        laudantium, totam rem aperiam, eaque ipsa quae ab illo
                        inventore veritatis et quasi architecto beatae vitae
                        dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                        voluptas sit aspernatur aut odit aut fugit, sed quia
                        consequuntur magni dolores eos qui ratione voluptatem
                        sequi nesciunt. Neque porro quisquam est, qui dolorem
                        ipsum quia dolor sit amet, consectetur, adipisci velit,
                        sed quia non numquam eius modi tempora incidunt ut
                        labore et dolore magnam aliquam quaerat voluptatem.
                      </td>
                    </tr>
                    <tr className="tr-dimensions">
                      <td className="td-label">Dimensions</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Loader isFetching={isFetching}/>
      {/* Page Loader */}
      {/* <div className="page-preloader">
    <div className="loader">
      <div />
      <div />
    </div>
  </div> */}
      {/* Dependency Scripts */}
      {/* Site Scripts */}
    </>
  );
};

export default ProductDetails;
