import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const homeProducts = createApi({
  reducerPath: "homeProducts",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://fine1jewels.com/mainapi/api/",
  }),
  endpoints: (builder) => {
    return {
      catProducts: builder.query({
        query: (params) => {
          return {
            url: `cat-products/${params.name}/${params.page}`,
            method: "GET",
          };
        },
      }),
      brandProducts: builder.query({
        query: (params) => {
          return {
            url: `brand-products/${params.name}/${params.page}`,
            method: "GET",
          };
        },
      }),
      searchProducts: builder.query({
        query: (params) => {
          return {
            url: `search-products/${params.keyword}/${params.page}`,
            method: "GET",
          };
        },
      }),
      trendingProducts: builder.query({
        query: (params) => {
          return {
            url: `trending-products/${params.page}`,
            method: "GET",
          };
        },
      }),
      getCartDetails: builder.query({
        query: (cartItems) => {
          return {
            url: `cart-details`,
            method: "POST",
            body: { cartItems },
          };
        },
      }),
    };
  },
});
export const { useCatProductsQuery,useBrandProductsQuery, useSearchProductsQuery,useTrendingProductsQuery,useGetCartDetailsQuery } = homeProducts;
export default homeProducts;
