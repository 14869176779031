import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import currency  from "currency-formatter";
import { usePlaceCashOnDeliveryOrderMutation, useSendPaymentMutation } from "../../store/services/paymentService";
import { useDispatch, useSelector } from "react-redux";
import { useGetCartDetailsQuery } from "../../store/services/homeProducts";
import Loader from "../../components/Loader/Loader";
import { useGetUserAddressesQuery } from "../../store/services/addressService";
import { useGetUserByIdQuery } from "../../store/services/authService";
import toast from "react-hot-toast";
import { emptyCart } from "../../store/reducers/cartReducer";

const Checkout = () => {
  const [doPayment, response] = useSendPaymentMutation();
  const [placeCodOrder] = usePlaceCashOnDeliveryOrderMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart, total,totalshipping } = useSelector((state) => state.cart);
  const { data, isFetching } = useGetCartDetailsQuery(cart);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('cod'); // default to 'cod'
  const { userToken, user } = useSelector((state) => state.authReducer);
  const { data: userProfile, isSuccess } = useGetUserByIdQuery(user.id);
  const { data: userAddresses, isLoading: isAddressloading } = useGetUserAddressesQuery(user.id);
  const [checkoutLoading, setcheckoutLoading] = useState(false)
  const [address, setAddress] = useState({
    firstname: '',
    lastname: '',
    companyname: '',
    country: '',
    streetname: '',
    apartment: '',
    city: '',
    state: '',
    postalCode: '',
    phone: '',
    emailaddress: '',
    ordernotes:''
  });

  useEffect(() => {
    if (userAddresses && userAddresses.length > 0) {
      const firstAddress = userAddresses[0];
      setAddress({
        firstname: userProfile.name,
        lastname: '',
        companyname: '',
        country: 'IN',
        streetname: firstAddress.addressLine1,
        apartment: '',
        phone: userProfile.phone,
        emailaddress: userProfile.email,
        ordernotes:'',
        city: firstAddress.city,
        postalCode: firstAddress.postalCode,
        state: firstAddress.state,
        country: firstAddress.country
      });
    }
  }, [userAddresses]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress(prevState => ({
      ...prevState,
      [name]: value
    }));
  };



  const handleSubmit = async (event) => {
    // Prevent the default form submission
    event.preventDefault();
    if (selectedPaymentMethod == "online") {
      setcheckoutLoading(true)
      doPayment({ cart, userId: user.id,address:address });
      console.log("Processing online payment...");
    } else if (selectedPaymentMethod == "cod") {
      const orderData = {
        cart, userId: user.id,address:address
      };
      try {
        const response = await placeCodOrder(orderData);
        setcheckoutLoading(true)
        console.log(response)
        toast.success(response.data.message);
        localStorage.removeItem("cart");
        dispatch(emptyCart());
        navigate("/user-order-details/"+response.data.lastOrderId);
        setcheckoutLoading(false)
      } catch (error) {
        console.log(error)
        toast.error("Some Error Occured")
      }
    }
    
    

  }




  useEffect(() => {
    if (response?.isSuccess) {
      window.location.href = response?.data?.url;
    }
  }, [response]);


  const handlePaymentChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };


  return (
    <>
      <div id="page" className="hfeed page-wrapper">
        <div id="site-main" className="site-main">
          <div id="main-content" className="main-content">
            <div id="primary" className="content-area">
              <div id="title" className="page-title">
                <div className="section-container">
                  <div className="content-title-heading">
                    <h1 className="text-title-heading">Checkout</h1>
                  </div>
                  <div className="breadcrumbs">
                    <Link to="/">Home</Link>
                    <span className="delimiter" />
                    <Link to="/Shop">Shop</Link>
                    <span className="delimiter" />
                    Checkout
                  </div>
                </div>
              </div>
              <div id="content" className="site-content" role="main">
                <div className="section-padding">
                  <div className="section-container p-l-r">
                    <div className="shop-checkout">
                      <form
                        name="checkout"
                        method="post"
                        className="checkout"
                        action=""
                        autoComplete="off"
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <div className="col-xl-8 col-lg-7 col-md-12 col-12">
                            <div className="customer-details">
                              <div className="billing-fields">
                                <h3>Billing Details</h3>
                                <div className="billing-fields-wrapper">
                                  <p className="form-row form-row-first validate-required">
                                    <label>
                                      First name{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <input
                                       type="text"
                                       className="input-text"
                                       name="firstname"
                                       value={address.firstname}
                                       onChange={handleChange}
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row form-row-last validate-required">
                                    <label>
                                      Last name{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <input
                                       type="text"
                                       className="input-text"
                                       name="lastname"
                                       value={address.lastname}
                                       onChange={handleChange}
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row form-row-wide">
                                    <label>
                                      Company name{" "}
                                      <span className="optional">
                                        (optional)
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        name="companyname"
                                        value={address.companyname}
                                        onChange={handleChange}
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row form-row-wide validate-required">
                                    <label>
                                      Country / Region{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <select
                                        name="country"
                                        className="country-select custom-select"
                                        value={address.country}
                                        onChange={handleChange}
                                      >
                                        <option value="">
                                          Select a country / region…
                                        </option>
                                        <option value="IN">India</option>
                                      
                                      </select>
                                    </span>
                                  </p>
                                  <p className="form-row address-field validate-required form-row-wide">
                                    <label>
                                      Street address{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <input
                                                            type="text"
                                                            className="input-text"
                                                            name="streetname"
                                                            placeholder="House number and street name"
                                                            value={address.streetname}
                                                            onChange={handleChange}
                                      
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row address-field form-row-wide">
                                    <label>
                                      Apartment, suite, unit, etc.&nbsp;
                                      <span className="optional">
                                        (optional)
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        name="apartment"
                                        placeholder="Apartment, suite, unit, etc. (optional)"
                                        value={address.apartment}
                                        onChange={handleChange}
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row address-field validate-required form-row-wide">
                                    <label htmlFor="billing_city" className="">
                                      Town / City{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <input
                                      type="text"
                                      className="input-text"
                                      name="city"
                                      value={address.city}
                                      onChange={handleChange}
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row address-field validate-required validate-state form-row-wide">
                                    <label>
                                      State / County{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <select
                                       name="state"
                                       className="state-select custom-select"
                                       value={address.state}
                                       onChange={handleChange}
                                      >
                                      <option value="AP">Andhra Pradesh</option>
<option value="AR">Arunachal Pradesh</option>
<option value="AS">Assam</option>
<option value="BR">Bihar</option>
<option value="CT">Chhattisgarh</option>
<option value="GA">Goa</option>
<option value="GJ">Gujarat</option>
<option value="HR">Haryana</option>
<option value="HP">Himachal Pradesh</option>
<option value="JK">Jammu and Kashmir</option>
<option value="JH">Jharkhand</option>
<option value="KA">Karnataka</option>
<option value="KL">Kerala</option>
<option value="MP">Madhya Pradesh</option>
<option value="MH">Maharashtra</option>
<option value="MN">Manipur</option>
<option value="ML">Meghalaya</option>
<option value="MZ">Mizoram</option>
<option value="NL">Nagaland</option>
<option value="OR">Odisha</option>
<option value="PB">Punjab</option>
<option value="RJ">Rajasthan</option>
<option value="SK">Sikkim</option>
<option value="TN">Tamil Nadu</option>
<option value="TG">Telangana</option>
<option value="TR">Tripura</option>
<option value="UP">Uttar Pradesh</option>
<option value="UT">Uttarakhand</option>
<option value="WB">West Bengal</option>
<option value="AN">Andaman and Nicobar Islands</option>
<option value="CH">Chandigarh</option>
<option value="DN">Dadra and Nagar Haveli and Daman and Diu</option>
<option value="LD">Lakshadweep</option>
<option value="DL">Delhi</option>
<option value="PY">Puducherry</option>
                                      </select>
                                    </span>
                                  </p>
                                  <p className="form-row address-field validate-required validate-postcode form-row-wide">
                                    <label>
                                      Postcode / ZIP{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        name="postalCode"
                                        value={address.postalCode}
                                        onChange={handleChange}
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row form-row-wide validate-required validate-phone">
                                    <label>
                                      Phone{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <input
                                      type="tel"
                                      className="input-text"
                                      name="phone"
                                      value={address.phone}
                                      onChange={handleChange}
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row form-row-wide validate-required validate-email">
                                    <label>
                                      Email address{" "}
                                      <span
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <span className="input-wrapper">
                                      <input
                                         type="email"
                                         className="input-text"
                                         name="emailaddress"
                                         value={address.emailaddress}
                                         onChange={handleChange}
                                         autoComplete="off"
                                      />
                                    </span>
                                  </p>
                                </div>
                              </div>
                              
                            </div>
                          
                            <div className="additional-fields">
                              <p className="form-row notes">
                                <label>
                                  Order notes{" "}
                                  <span className="optional">(optional)</span>
                                </label>
                                <span className="input-wrapper">
                                  <textarea
                                    
                                    className="input-text"
                                    placeholder="Notes about your order, e.g. special notes for delivery."
                                    rows={2}
                                    cols={5}
                                    defaultValue={""}


                                    type="text"
                                    name="ordernotes"
                                    value={address.ordernotes}
                                    onChange={handleChange}
                                  />
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-5 col-md-12 col-12">
                            <div className="checkout-review-order">
                              <div className="checkout-review-order-table">
                                <h3 className="review-order-title">Product</h3>
                                <div className="cart-items">
                                {data?.products.map((item, index) => {
return( 
                                  <div className="cart-item">
                                    <div className="info-product">
                                      <div className="product-thumbnail">
                                        <img
                                          width={600}
                                          height={600}
                                          src={`https://fine1jewels.com/images/${item.image}`}
                                          alt=""
                                        />
                                      </div>
                                      <div className="product-name">
                                      {item?.title}
                                        <strong className="product-quantity">
                                          QTY : {item.quantity}
                                        </strong>
                                      </div>
                                    </div>
                                    <div className="product-total">
                                      <span>{currency.format(item.subtotal, { code: "INR" })}</span>
                                    </div>
                                  </div>)})}
                                 
                                </div>
                                <div className="cart-subtotal">
                                  <h2>Subtotal</h2>
                                  <div className="subtotal-price">
                                    <span>{currency.format(data?.subtotal, { code: "INR" })}</span>
                                  </div>
                                </div>
                                <div className="shipping-totals shipping">
                                  <h2>Shipping</h2>
                                  <div data-title="Shipping">
                                    {/* <ul className="shipping-methods custom-radio">
                                      <li>
                                        <input
                                          type="radio"
                                          name="shipping_method"
                                          data-index={0}
                                          defaultValue="free_shipping"
                                          className="shipping_method"
                                          defaultChecked="checked"
                                        />
                                        <label>Free shipping</label>
                                      </li>
                                      <li>
                                        <input
                                          type="radio"
                                          name="shipping_method"
                                          data-index={0}
                                          defaultValue="flat_rate"
                                          className="shipping_method"
                                        />
                                        <label>Flat rate</label>
                                      </li>
                                    </ul> */}
                                    <ul className="shipping-methods custom-radio">
                                     <span>{currency.format(data?.shippingCost, { code: "INR" })}</span></ul>
                                  </div>
                                </div>
                                <div className="order-total">
                                  <h2>Total</h2>
                                  <div className="total-price">
                                    <strong>
                                      <span>{currency.format(data?.totalPrice, { code: "INR" })}</span>
                                    </strong>
                                  </div>
                                </div>
                              </div>
                              <div id="payment" className="checkout-payment">
        <ul className="payment-methods methods custom-radio">
          <li className="payment-method">
            <input
              type="radio"
              className="input-radio"
              name="payment_method"
              value="online"
              checked={selectedPaymentMethod === 'online'}
              onChange={handlePaymentChange}
            />
            <label htmlFor="payment_method_bacs">Online Payment</label>
            {selectedPaymentMethod === 'online' && (
              <div className="payment-box">
                <p>
                  Make your payment directly into our bank account. Please use
                  your Order ID as the payment reference. Your order will not be
                  shipped until the funds have cleared in our account.
                </p>
              </div>
            )}
          </li>

          <li className="payment-method">
            <input
              type="radio"
              className="input-radio"
              name="payment_method"
              value="cod"
              checked={selectedPaymentMethod === 'cod'}
              onChange={handlePaymentChange}
            />
            <label>Cash on Delivery</label>
            {selectedPaymentMethod === 'cod' && (
              <div className="payment-box">
                <p>Pay with cash upon delivery.</p>
              </div>
            )}
          </li>
        </ul>

        <div className="form-row place-order">
          <button
            disabled={checkoutLoading}
            type="submit"
            className="button alt"
            name="checkout_place_order"
          >
            {checkoutLoading ? 'Processing' : 'Place Order'}
          </button>
        </div>
      </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* #content */}
            </div>
            {/* #primary */}
          </div>
          {/* #main-content */}
        </div>
      </div>
      {/* Back Top button */}
      <div className="back-top button-show">
        <i className="arrow_carrot-up" />
      </div>
      {/* Search */}
      <div className="search-overlay">
        <div className="close-search" />
        <div className="wrapper-search">
          <form
            role="search"
            method="get"
            className="search-from ajax-search"
            action=""
          >
            <a href="#" className="search-close" />
            <div className="search-box">
              <button id="searchsubmit" className="btn" type="submit">
                <i className="icon-search" />
              </button>
              <input
                type="text"
                autoComplete="off"
                defaultValue=""
                name="s"
                className="input-search s"
                placeholder="Search..."
              />
              <div className="content-menu_search">
                <label>Suggested</label>
                <ul id="menu_search" className="menu">
                  <li>
                    <a href="#">Earrings</a>
                  </li>
                  <li>
                    <a href="#">Necklaces</a>
                  </li>
                  <li>
                    <a href="#">Bracelets</a>
                  </li>
                  <li>
                    <a href="#">Jewelry Box</a>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Wishlist */}
    
      {/* Page Loader */}
   <Loader isFetching={isFetching}/>
      {/* Dependency Scripts */}
      {/* Site Scripts */}
    </>
  );
};

export default Checkout;
