import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TwitterPicker } from "react-color";
import { v4 as uuidv4 } from 'uuid';
import ReactQuill from "react-quill";
import toast, { Toaster } from 'react-hot-toast';
import 'react-quill/dist/quill.snow.css';
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper";
import { useAllCategoriesQuery } from "../../store/services/categoryService";
import { useCProductMutation } from "../../store/services/productService";
import Spinner from "../../components/Spinner";
import Colors from "../../components/Colors";
import SizesList from "../../components/SizesList";
import ImagesPreview from "../../components/ImagesPreview";
import { setSuccess } from "../../store/reducers/globalReducer";
import { useGetBrandsQuery } from "../../store/services/brandService";
import { useCreateSizeMutation, useGetAllSizesQuery, useRemoveSizeMutation } from "../../store/services/sizeService";
import { AiOutlineDelete } from 'react-icons/ai';

const CreateProduct = () => {
    const { data: allsizes, error, isLoading } = useGetAllSizesQuery();
    const [createSize] = useCreateSizeMutation();
    const [removeSize] = useRemoveSizeMutation();
    const [submitting, setsubmitting] = useState(false)

    const [sizes, setSizes] = useState([
        { name: '26', price: 0 }, { name: '28', price: 0 }, { name: '30', price: 0 },
        { name: '32', price: 0 }, { name: '34', price: 0 }, { name: '36', price: 0 },
        { name: '38', price: 0 }, { name: '40', price: 0 }, { name: '42', price: 0 },
        { name: '44', price: 0 }
    ]);

    useEffect(() => {
        if (allsizes) {
            setSizes(allsizes.map(size => ({ name: size.name, _id: size._id, price: 0 })));
        }
    }, [allsizes]);

    const { data = [], isFetching } = useAllCategoriesQuery();
    const { data: allbrands = [], isFetching: fetchingbrands } = useGetBrandsQuery();
    const [value, setValue] = useState('');
    const [state, setState] = useState({
        title: '',
        discount: 0,
        stock: 100,
        category: '',
        colors: [],
        shipping: 0,
        shipdetails: '',
        brandName: '',
        trendingProduct: false,
    });

    const [metalDetails, setMetalDetails] = useState({
        type: '',
        karat: 0,
        grossWeight: 0,
        netWeight: 0,
        wastagePercentage: 0,
        pureGoldWeight: 0,
        metalRate: 0,
        metalPrice: 0
    });

    const [stoneDetails, setStoneDetails] = useState({
        diamond: {
            pcs: 0,
            carats: 0,
            ratePerCarat: 0,
            amount: 0
        },
        otherStones: []
    });

    const [labourDetails, setLabourDetails] = useState({
        ratePerGram: 0,
        labourAmount: 0
    });

    const [pricing, setPricing] = useState({
        totalPrice: 0,
        gst: 0,
        finalPrice: 0
    });

    const [sizeList, setSizeList] = useState([]);
    const [customSize, setCustomSize] = useState('');
    const [images, setImages] = useState([]);
    const [preview, setPreview] = useState([]);

    const handleInput = e => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleMetalDetailsChange = e => {
        setMetalDetails({ ...metalDetails, [e.target.name]: e.target.value });
    };

    const handleStoneDetailsChange = (e, type, index) => {
        if (type === 'diamond') {
            setStoneDetails({
                ...stoneDetails,
                diamond: { ...stoneDetails.diamond, [e.target.name]: e.target.value }
            });
        } else {
            const updatedOtherStones = [...stoneDetails.otherStones];
            updatedOtherStones[index] = { ...updatedOtherStones[index], [e.target.name]: e.target.value };
            setStoneDetails({ ...stoneDetails, otherStones: updatedOtherStones });
        }
    };

    const handleLabourDetailsChange = e => {
        setLabourDetails({ ...labourDetails, [e.target.name]: e.target.value });
    };

    const handlePricingChange = e => {
        setPricing({ ...pricing, [e.target.name]: e.target.value });
    };

    const addOtherStone = () => {
        setStoneDetails({
            ...stoneDetails,
            otherStones: [...stoneDetails.otherStones, { name: '', pcs: 0, carats: 0, ratePerCarat: 0, amount: 0 }]
        });
    };

    const handleSizePriceChange = (sizeName, price) => {
        setSizeList(sizeList.map(size => size.name === sizeName ? { ...size, price: parseFloat(price) } : size));
    };

    const saveColors = (color) => {
        const filtered = state.colors.filter((clr) => clr.color !== color.hex);
        setState({ ...state, colors: [...filtered, { color: color.hex, id: uuidv4() }] });
    };

    const deleteColor = color => {
        const filtered = state.colors.filter(clr => clr.color !== color.color);
        setState({ ...state, colors: filtered });
    };

    const chooseSize = sizeObject => {
        if (!sizeList.find(size => size.name === sizeObject.name)) {
            setSizeList([...sizeList, { ...sizeObject, price: 0 }]);
        }
    };

    const deleteSize = name => {
        const filtered = sizeList.filter(size => size.name !== name);
        setSizeList(filtered);
    };

    const handleCustomSizeChange = e => {
        setCustomSize(e.target.value);
    };

    const addCustomSize = async () => {
        const sizeData = { name: customSize };
        await createSize(sizeData);
        if (customSize && !sizeList.find(size => size.name === customSize)) {
            setSizeList([...sizeList, { name: customSize, price: 0 }]);
            setCustomSize('');
        } else {
            toast.error('Size already exists or is invalid');
        }
    };

    const handleImageChange = e => {
        const files = Array.from(e.target.files);
        setImages(files);

        const filePreviews = files.map(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            return new Promise(resolve => {
                reader.onloadend = () => resolve(reader.result);
            });
        });

        Promise.all(filePreviews).then(previews => setPreview(previews));
    };

    const [createNewProduct, response] = useCProductMutation();
    const createPro = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('data', JSON.stringify(state));
        formData.append('metalDetails', JSON.stringify(metalDetails));
        formData.append('stoneDetails', JSON.stringify(stoneDetails));
        formData.append('labourDetails', JSON.stringify(labourDetails));
        formData.append('pricing', JSON.stringify(pricing));
        formData.append('sizes', JSON.stringify(sizeList));
        formData.append('description', value);
        images.forEach((image, index) => {
            formData.append('images', image);
        });
        createNewProduct(formData);
    };

    useEffect(() => {
        if (!response.isSuccess) {
            response?.error?.data?.errors.map(err => {
                toast.error(err.msg);
            });
        }
    }, [response?.error?.data?.errors]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (response?.isSuccess) {
            dispatch(setSuccess(response?.data?.msg));
            navigate('/dashboard/products');
        }
    }, [response?.isSuccess]);

    const handleTrendingChange = () => {
        setState({ ...state, trendingProduct: !state.trendingProduct });
    };

    return (
       
        <Wrapper>
            <ScreenHeader>
                <Link to="/dashboard/products" className="btn-dark">
                    <i className="bi bi-arrow-left-short"></i> products list
                </Link>
            </ScreenHeader>
            <Toaster position="top-right" reverseOrder={true} />
            <div className="flex flex-wrap -mx-3">
                <form className="w-full xl:w-8/12 p-3" onSubmit={createPro}>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="title" className="label">title</label>
                            <input type="text" name="title" className="form-control" id="title" placeholder="title..." onChange={handleInput} value={state.title} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="discount" className="label">discount</label>
                            <input type="number" name="discount" className="form-control" id="discount" placeholder="discount..." onChange={handleInput} value={state.discount} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="stock" className="label">stock</label>
                            <input type="number" name="stock" className="form-control" id="stock" placeholder="stock..." onChange={handleInput} value={state.stock} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="shipping" className="label">shipping</label>
                            <input type="number" name="shipping" className="form-control" id="shipping" placeholder="shipping..." onChange={handleInput} value={state.shipping} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="shipdetails" className="label">shipdetails</label>
                            <input type="text" name="shipdetails" className="form-control" id="shipdetails" placeholder="shipdetails..." onChange={handleInput} value={state.shipdetails} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="category" className="label">category</label>
                            {isFetching ? <Spinner /> : data?.categories?.length > 0 && (
                                <select className="form-control" name="category" id="category" onChange={handleInput} value={state.category}>
                                    <option value="">Choose category</option>
                                    {data?.categories?.map((category) => (
                                        <option value={category._id} key={category._id}>{category.name}</option>
                                    ))}
                                </select>
                            )}
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="brand" className="label">brand</label>
                            {fetchingbrands ? <Spinner /> : allbrands?.brands?.length > 0 && (
                                <select className="form-control" name="brandName" id="brandName" onChange={handleInput} value={state.brandName}>
                                    <option value="">Choose brand</option>
                                    {allbrands?.brands?.map((brand) => (
                                        <option value={brand.name} key={brand._id}>{brand.name}</option>
                                    ))}
                                </select>
                            )}
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label className="label">Trending Product</label>
                            <input type="checkbox" checked={state.trendingProduct} onChange={handleTrendingChange} />
                        </div>
                    </div>
                    <div className="w-full p-3">
                        <label className="label">Description</label>
                        <ReactQuill theme="snow" value={value} onChange={setValue} />
                    </div>

                        <div className="w-full p-3">
                            <label htmlFor="images" className="label">Upload Images</label>
                            <input type="file" name="images" id="images" className="input-file" onChange={handleImageChange} multiple />
                        </div>
                       



                    <div className="container">
      {/* Metal Details Section */}
      <div className="w-full p-3">
        <label className="label">Metal Details</label>
        <div className="flex flex-wrap">
          {/* Metal Details Fields */}
          {Object.keys(metalDetails).map((key) => (
            <div key={key} className="w-full md:w-4/12 p-3">
                <label className="label">{key}</label>
              <input
              
              
                type={key === 'karat' || key.includes('Weight') || key.includes('Rate') || key.includes('Price') ? 'number' : 'text'}
                name={key}
                className="form-control"
                placeholder={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                value={metalDetails[key]}
                onChange={handleMetalDetailsChange}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Stone Details Section */}
      <div className="w-full p-3">
        <label className="label">Stone Details</label>
        <div className="flex flex-wrap">
          {/* Diamond Details */}
          {Object.keys(stoneDetails.diamond).map((key) => (
            <div key={key} className="w-full md:w-4/12 p-3">
                <label className="label">{key}</label>
              <input
                type="number"
                name={key}
                className="form-control"
                placeholder={`Diamond ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                value={stoneDetails.diamond[key]}
                onChange={(e) => handleStoneDetailsChange(e, 'diamond')}
              />
            </div>
          ))}

          {/* Other Stones Details */}
          {stoneDetails.otherStones.map((stone, index) => (
            <div key={index} className="w-full flex flex-wrap p-3">
              {Object.keys(stone).map((key) => (
                <div key={key} className="w-full md:w-6/12 p-3">
                <label className="label">{key}</label>
                <input
                  key={key}
                  type={key === 'name' ? 'text' : 'number'}
                  name={key}
                  className="form-control w-1/3 p-2"
                  placeholder={`Stone ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                  value={stone[key]}
                  onChange={(e) => handleStoneDetailsChange(e, 'other', index)}
                />
                </div>
              ))}
              <div  className="w-full md:w-6/12 p-3">
              
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => setStoneDetails({
                  ...stoneDetails,
                  otherStones: stoneDetails.otherStones.filter((_, i) => i !== index),
                })}
              >
                <AiOutlineDelete />
              </button>
            </div>
            </div>
          ))}

          {/* Add Another Stone Button */}
          <div className="w-full p-3">
            <button type="button" className="btn btn-secondary" onClick={addOtherStone}>
              Add Another Stone
            </button>
          </div>
        </div>
      </div>

      {/* Labour Details Section */}
      <div className="w-full p-3">
        <label className="label">Labour Details</label>
        <div className="flex flex-wrap">
          {/* Labour Details Fields */}
          {Object.keys(labourDetails).map((key) => (
            <div key={key} className="w-full md:w-6/12 p-3">
                <label className="label">{key}</label>
              <input
                type="number"
                name={key}
                className="form-control"
                placeholder={key === 'ratePerGram' ? 'Rate Per Gram' : 'Labour Amount'}
                value={labourDetails[key]}
                onChange={handleLabourDetailsChange}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
                    {/* <div className="w-full p-3">
                        <label className="label">Additional Details</label>
                        <textarea className="form-control" rows="3" placeholder="Additional details" onChange={handleAdditionalDetailsChange} value={additionalDetails}></textarea>
                    </div> */}

                    
                    <div className="w-full p-3">
                        <button type="submit" className="btn btn-primary" disabled={submitting}>
                            {submitting ? "Submitting..." : "Submit"}
                        </button>
                    </div>
            </form>

                <div className="w-full xl:w-4/12 p-3">
                    <Colors colors={state.colors} deleteColor={deleteColor} />
                    <SizesList list={sizeList} deleteSize={deleteSize} />
                    {preview.map((url, index) => (
                        <ImagesPreview key={index} url={url} heading={`Image ${index + 1}`} />
                    ))}
                </div>
           

                </div>
                </Wrapper>
  );
};

export default CreateProduct;
