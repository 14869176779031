import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="site-footer" className="site-footer background four-columns">
      <div className="footer">
        <div className="section-padding">
          <div className="section-container">
            <div className="block-widget-wrap">
              <div className="row">
                <div className="col-lg-3 col-md-6 column-1">
                  <div className="block block-menu m-b-20">
                    <h2 className="block-title">Get in Touch</h2>
                    <div className="block-content">
                      <ul>
                        <li>
                          <span>Head Office:</span> 26 Wyle Cop, Shrewsbury,
                          Shropshire, SY1 1XD
                        </li>
                        <li>
                          <span>Phone:</span>{" "}
                          <Link to="tel:01743234500">01743 234500</Link>
                        </li>
                        <li>
                          <span>Email:</span>{" "}
                          <Link to="mailto:support@Fine1Jewels.com">
                            support@Fine1Jewels.com
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="block block-social">
                    <h2 className="block-title">Follow Us</h2>
                    <ul className="social-link">
                      <li>
                        <Link to="#" aria-label="Twitter">
                          <i className="fa fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" aria-label="Instagram">
                          <i className="fa fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" aria-label="Dribbble">
                          <i className="fa fa-dribbble" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" aria-label="Behance">
                          <i className="fa fa-behance" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 column-2">
                  <div className="block block-menu">
                    <h2 className="block-title">Customer Services</h2>
                    <div className="block-content">
                      <ul>
                        <li>
                          <Link to="/Contact">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/Shop">Track Your Order</Link>
                        </li>
                        <li>
                          <Link to="/Shop">Product Care &amp; Repair</Link>
                        </li>
                        <li>
                          <Link to="/Shop">Book an Appointment</Link>
                        </li>
                        <li>
                          <Link to="/Shop">FAQs</Link>
                        </li>
                        <li>
                          <Link to="/Shop">Shipping &amp; Returns</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 column-3">
                  <div className="block block-menu">
                    <h2 className="block-title">About Us</h2>
                    <div className="block-content">
                      <ul>
                        <li>
                          <Link to="#">Our Story</Link>
                        </li>
                        <li>
                          <Link to="#">FAQ</Link>
                        </li>
                        <li>
                          <Link to="#">Our Producers</Link>
                        </li>
                        <li>
                          <Link to="#">Sitemap</Link>
                        </li>
                        <li>
                          <Link to="#">Terms &amp; Conditions</Link>
                        </li>
                        <li>
                          <Link to="#">Privacy Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 column-4">
                  <div className="block block-menu">
                    <h2 className="block-title">Shop by Category</h2>
                    <div className="block-content">
                      <ul>
                        <li>
                          <Link to="/Shop">Earrings</Link>
                        </li>
                        <li>
                          <Link to="/Shop">Necklaces</Link>
                        </li>
                        <li>
                          <Link to="/Shop">Bracelets</Link>
                        </li>
                        <li>
                          <Link to="/Shop">Rings</Link>
                        </li>
                        <li>
                          <Link to="/Shop">Jewelry Box</Link>
                        </li>
                        <li>
                          <Link to="/Shop">Studs</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="section-padding">
          <div className="section-container">
            <div className="block-widget-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="footer-left">
                    <p className="copyright">
                      &copy; 2024 Fine1Jewels. All Rights Reserved.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer-right">
                    <div className="block block-image">
                      <img
                        width={309}
                        height={32}
                        src="media/payments.png"
                        alt="Payment Methods"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
