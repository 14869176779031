import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const contactUsService = createApi({
    reducerPath: 'contactUs',
    tagTypes: 'contactUs',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://fine1jewels.com/mainapi/api/', // Change this to your actual API URL
        prepareHeaders: (headers, { getState }) => {
            // Add any authentication headers if required
            return headers;
        }
    }),
    endpoints: (builder) => {
        return {
            createContactUs: builder.mutation({
                query: (data) => {
                    return {
                        url: '/create-contactus',
                        method: 'POST',
                        body: data
                    }
                },
                invalidatesTags: ['contactUs']
            }),
            editContactUs: builder.mutation({
                query: ({ id, ...data }) => {
                    return {
                        url: `/edit-contactus/${id}`,
                        method: 'PUT',
                        body: data
                    }
                },
                invalidatesTags: ['contactUs']
            }),
            getAllContactUs: builder.query({
                query: () => {
                    return {
                        url: '/contactus',
                        method: 'GET'
                    }
                }
            }),
            getContactUsById: builder.query({
                query: (id) => {
                    return {
                        url: `/contactus/${id}`,
                        method: 'GET'
                    }
                }
            }),
        }
    }
});

export const {
    useCreateContactUsMutation,
    useEditContactUsMutation,
    useGetAllContactUsQuery,
    useGetContactUsByIdQuery
} = contactUsService;

export default contactUsService;
