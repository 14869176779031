
import React, { useEffect, useState } from 'react'
import { useUserLoginMutation, useUserRegisterMutation } from '../../store/services/authService';
import { useNavigate } from 'react-router-dom';
import { setUserToken } from '../../store/reducers/authReducer';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

const LoginModal = ({showModal,setShowModal,}) => {
    const dispatch = useDispatch();
    const [showlogin, setshowlogin] = useState(true)
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState(""); // Changed from 'country' to 'state'
  const [zip, setZip] = useState("");
  
  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errClientName, setErrClientName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [errAddress, setErrAddress] = useState("");
  const [errCity, setErrCity] = useState("");
  const [errState, setErrState] = useState(""); // Changed from 'errCountry' to 'errState'
  const [errZip, setErrZip] = useState("");
  // ============= Error Msg End here ===================
  const [successMsg, setSuccessMsg] = useState("");
  const [errors, setErrors] = useState([]);
  const [registerUser, response] = useUserRegisterMutation();

  // ============= Event Handlers Start here =============
  const handleName = (e) => {
    setClientName(e.target.value);
    setErrClientName("");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
    setErrPhone("");
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
    setErrAddress("");
  };
  const handleCity = (e) => {
    setCity(e.target.value);
    setErrCity("");
  };
  const handleState = (e) => {
    setState(e.target.value);
    setErrState("");
  };
  const handleZip = (e) => {
    setZip(e.target.value);
    setErrZip("");
  };
  // ============= Event Handlers End here ===============

  // ================= Email Validation start here =============
  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };
  // ================= Email Validation End here ===============

  const handleSignUp = (e) => {
    e.preventDefault();
    
      if (!clientName) {
        setErrClientName("Enter your name");
      }
      if (!email) {
        setErrEmail("Enter your email");
      } else {
        if (!EmailValidation(email)) {
          setErrEmail("Enter a valid email");
        }
      }
      if (!phone) {
        setErrPhone("Enter your phone number");
      }
      if (!password) {
        setErrPassword("Create a password");
      } else {
        if (password.length < 6) {
          setErrPassword("Passwords must be at least 6 characters");
        }
      }
      if (!address) {
        setErrAddress("Enter your address");
      }
      if (!city) {
        setErrCity("Enter your city name");
      }
      if (!state) {
        setErrState("Enter the state you are residing");
      }
      if (!zip) {
        setErrZip("Enter the zip code of your area");
      }
      // ============== Getting the value ==============
      if (
        clientName &&
        email &&
        phone &&
        EmailValidation(email) &&
        password &&
        password.length >= 6 &&
        address &&
        city &&
        state &&
        zip
      ) {
        registerUser({
          email: email,
          password: password,
          phone: phone,
          name: clientName,
          address,
          city,
          state,
          zip,
        });
        setSuccessMsg(
          `Hello dear ${clientName}, Welcome to Fine1Jewels. We received your Sign up request. We are processing to validate your access. Till then stay connected, and additional assistance will be sent to you by your mail at ${email}`
        );
        // Reset form fields
        setClientName("");
        setEmail("");
        setPhone("");
        setPassword("");
        setAddress("");
        setCity("");
        setState("");
        setZip("");
      }
    
  };

  useEffect(() => {
    if (response.isError) {
      setErrors(response?.error?.data?.errors);
    }
  }, [response?.error?.data]);

  const navigate = useNavigate();

  useEffect(() => {
    if (response.isSuccess) {
      localStorage.setItem("userToken", response?.data?.token);
      dispatch(setUserToken(response?.data?.token));
      toast.success(response.data.msg);
      setShowModal(false)
      navigate("/");
    }
  }, [response.isSuccess, dispatch, navigate]);



  const [loginUser, { isLoading, isError, isSuccess, error, data }] = useUserLoginMutation();
  

  const handleLogin = (e) => {
    e.preventDefault();

    if (!email) {
      setErrEmail("Enter your email");
      return;
    } else {
      setErrEmail('');
    }

    if (!password) {
      setErrPassword("Create a password");
      return;
    } else {
      setErrPassword('');
    }

    // Call the mutation if all validations pass
    loginUser({ email, password });
    setEmail("");
    setPassword("");
  };

  useEffect(() => {
    if (isError) {
      setErrors(error?.data?.errors);
    }
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem('userToken', data?.token);
      dispatch(setUserToken(data?.token));
      setShowModal(false)
      toast.success('Welcome User!');
      
    }
  }, [isSuccess, data, dispatch, navigate]);

  return showModal ? (
    <div className="form-login-register show active">
    <div className="box-form-login">
      <div className="active-login" onClick={() => setShowModal(false)}/>
      <div className="box-content">
        <div className={`form-login ${showlogin ? "active":""}`} >
          <form onSubmit={handleLogin} method="post" className="login">
            <h2>Sign in</h2>
            <p className="status" />
            <div className="content">
              <div className="username">
                <input
                  type="email"
                  className="input-text"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmail}
                />
                 {errEmail &&  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                    <span className="font-bold italic mr-1">!</span>{errEmail}</p>}
              </div>
              <div className="password">
                <input
                  type="password"
                  className="input-text"
                  placeholder="Password"
                  value={password}
                  onChange={handlePassword}
                />
                {errPassword &&  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                    <span className="font-bold italic mr-1">!</span>{errPassword}</p>}
              </div>
              <div className="rememberme-lost">
                <div className="rememberme">
                  <input
                    name="rememberme"
                    type="checkbox"
                    id="rememberme"
                    defaultValue="forever"
                  />
                  <label htmlFor="rememberme" className="inline">
                    Remember me
                  </label>
                </div>
                {/* <div className="lost_password">
                  <a href="forgot-password.html">Lost your password?</a>
                </div> */}
              </div>
              <div className="button-login">
                <input
                  type="submit"
                  className="button"
                  name="login"
                  defaultValue="Login"
                />
              </div>
              <div onClick={() => setshowlogin(false)} className="button-next-reregister">
                Create An Account
              </div>
            </div>
          </form>
        </div>
        <div className={`form-register ${showlogin ? "":"active"}`}>
        {successMsg ? <h5 className="text-2xl text-green-500 font-titleFont font-semibold px-4">{successMsg}</h5>:    <form method="post" className="register" onSubmit={handleSignUp}>
<h2>REGISTER</h2>
<div className="content">
  <div className="email">
    <input
      type="text"
      className="input-text"
      placeholder="Name"
      value={clientName}
      onChange={handleName}
    />
    {errClientName &&  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                <span className="font-bold italic mr-1">!</span>{errClientName}</p>}
  </div>
  <div className="email">
    <input
      type="email"
      className="input-text"
      placeholder="Email"
      value={email}
      onChange={handleEmail}
    />
    {errEmail &&  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                <span className="font-bold italic mr-1">!</span>{errEmail}</p>}
  </div>
  <div className="email">
    <input
      type="text"
      className="input-text"
      placeholder="Phone"
      value={phone}
      onChange={handlePhone}
    />
    {errPhone &&  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                <span className="font-bold italic mr-1">!</span>{errPhone}</p>}
  </div>
  <div className="email">
    <input
      type="password"
      className="input-text"
      placeholder="Password"
      value={password}
      onChange={handlePassword}
    />
    {errPassword &&  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                <span className="font-bold italic mr-1">!</span>{errPassword}</p>}
  </div>
  <div className="email">
    <input
      type="text"
      className="input-text"
      placeholder="Address"
      value={address}
      onChange={handleAddress}
    />
    {errAddress &&  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                <span className="font-bold italic mr-1">!</span>{errAddress}</p>}
  </div>
  <div className="email">
    <input
      type="text"
      className="input-text"
      placeholder="City"
      value={city}
      onChange={handleCity}
    />
    {errCity &&  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                <span className="font-bold italic mr-1">!</span>{errCity}</p>}
  </div>
  <div className="email">
    <input
      type="text"
      className="input-text"
      placeholder="State"
      value={state}
      onChange={handleState}
    />
    {errState &&  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                <span className="font-bold italic mr-1">!</span>{errState}</p>}
  </div>
  <div className="email">
    <input
      type="text"
      className="input-text"
      placeholder="Zip Code"
      value={zip}
      onChange={handleZip}
    />
    {errZip &&  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                <span className="font-bold italic mr-1">!</span>{errZip}</p>}
  </div>
  <div className="button-register">
    <input type="submit" className="button" value="Register" />
  </div>
  <div className="button-next-login" onClick={() => setshowlogin(true)}>
    Already has an account
  </div>
</div>

</form>}
        </div>
      </div>
    </div>
  </div>
  ):null
}

export default LoginModal
